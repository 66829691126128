<template>
  <Page title="Anwesenheitskontrollen" color="info">
    <template slot="extension">
      <v-tabs
        background-color="info darken-1"
        :align-with-title="!$vuetify.breakpoint.xs"
        slider-color="white"
      >
        <v-tab :to="{ name: 'AttendanceChecksCurrent' }">Aktuell</v-tab>
        <v-tab :to="{ name: 'AttendanceChecksArchive' }">Vergangen</v-tab>
        <v-tab
          :to="{ name: 'AttendanceChecksSpecial' }"
          v-if="$vuetify.breakpoint.xs"
          >Sonder</v-tab
        >
        <v-tab :to="{ name: 'AttendanceChecksSpecial' }" v-else
          >Sonderveranstaltungen</v-tab
        >
      </v-tabs>
    </template>
    <router-view></router-view>
  </Page>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  data() {
    return {};
  },
});
</script>
